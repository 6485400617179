import React from "react";
import "./Team.css";
import TeamItem from "./TeamItem";
import { useEffect } from "react";
import gsap from "gsap";
import dav from "../assets/team-icone/Dav.png";
import ced from "../assets/team-icone/Ced.png";
import daniel from "../assets/team-icone/Daniel.jpeg";
import anass from "../assets/team-icone/Anass.png";
import geek from "../assets/team-icone/image0.jpeg";
import yoko from "../assets/team-icone/Yonko16_091.jpg";
import axel from "../assets/team-icone/axel.jpeg";
import jul from "../assets/team-icone/julian.jpg";
import jothomas from "../assets/team-icone/jo thomas.jpg";
import new1 from "../assets/team-icone/teamnew1.png";
import new2 from "../assets/team-icone/teamnew2.png";
import new3 from "../assets/team-icone/new3.png";
import storm from "../assets/team-icone/jstorm.jpeg";

function Team(){
    useEffect(()=>{
     //   window.addEventListener("mousemove",function(event){
     //       var width = this.window.innerWidth,
      //      height = this.window.innerHeight,
      //      positionX = (event.clientX/width) - 0.55,
      //      positionY = (event.clientY/height) - 0.55;
      //      gsap.to(".team-row1-container",{
      //          rotationY : positionX * 40,
      //          rotationX: -positionY * 40,
      //          ease: "none"
     //       })
     //   })

   //     window.addEventListener("mousemove",function(event){
        //    var width = this.window.innerWidth,
      //      height = this.window.innerHeight,
       //     positionX = (event.clientX/width) - 0.55,
      //     positionY = (event.clientY/height) - 0.55;
     //       gsap.to(".team-row2-container",{
       //         rotationY : positionX * 40,
       //         rotationX: -positionY * 40,
       //         ease: "none"
      //      })
       // })
     //   window.addEventListener("mousemove",function(event){
      //      var width = this.window.innerWidth,
      //      height = this.window.innerHeight,
       //     positionX = (event.clientX/width) - 0.55,
        //    positionY = (event.clientY/height) - 0.55;
       //     gsap.to(".team-row3-container",{
       //         rotationY : positionX * 40,
       //         rotationX: -positionY * 40,
       //         ease: "none"
       //     })
      //  })

        const target2 = '.header-team-container'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target2, {
            opacity: 0
        })

        // And here all the div is looping through
        
            gsap.to(target2, {
                scrollTrigger: {
                    trigger: target2,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 100,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })

        const target3 = '.about-us-container'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target3, {
            opacity: 0
        })

        // And here all the div is looping through
        
            gsap.to(target3, {
                scrollTrigger: {
                    trigger: target3,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 100,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })


        

    },[])

    return(
        <div className="team-container">
            <div className="header-team-container">
                <h1 id="team-header">
                    THE TEAM
                </h1>
            </div>
            <div className="about-us-container">
                
                <p>
                Shinsekai is run by a team of successful entrepreneurs, artists, marketers, and crypto & NFT enthusiasts.
                It is their passion for anime and manga that brought them together in this project.
                </p>
            </div>
            <div className="team-row1-container columns is-multiline">              
                <TeamItem picture={ced} name={"KASHAMA"} role={"Founder & Designer"} instagram={"https://instagram.com/imkashama?utm_medium=copy_link"} tiktok={"https://www.tiktok.com/@imkashama?lang=fr"}/>
                <TeamItem picture={jul} name={"JULIAN"} role={"Serial Entrepreneur"} instagram={"https://instagram.com/anckor?utm_medium=copy_link"} linkedin={"https://www.linkedin.com/in/julianohayon/"}/>
                <TeamItem picture={dav} name={"BLUE SKY"} role={"Project Manager"} instagram={"https://www.instagram.com/davidemmanuel_g/"} />
                <TeamItem picture={daniel} name={"MISTER D"} role={"Marketer"} instagram={"https://www.instagram.com/daniel__duca/"} linkedin={"https://www.linkedin.com/in/daniel-duca/"} />
                <TeamItem picture={storm} name={"Jstorm"} role={"NFT expert"} twitter ={"https://twitter.com/Jon__storm"}/>
                <TeamItem picture={anass} name={"BUU"} role={"Creative director"}/>

                <TeamItem picture={jothomas} name={"THOMAS"} role={"Strategy Advisor"} twitter= {"https://twitter.com/FOMOBOYZS"} linkedin={"https://www.linkedin.com/in/thomassalgues"}/>
                <TeamItem picture={geek} name={"JO"} role={"Logistics"} linkedin={"https://www.linkedin.com/in/jonathan-f-92013aaa/"}/>
                <TeamItem picture={new2} name={"YOSHI"} role={"Community manager"} twitter={"https://twitter.com/yoshi_mlk"}/> 
                <TeamItem picture={yoko} name={"TAC"} role={"Community manager"} twitter={"https://twitter.com/M0RNG0ETH"}/>
                <TeamItem picture={axel} name={"NEOCORX"} role={"Discord Advisor"}/>

                <TeamItem picture={new3} name={"LEO"} role={"Full Stack Developer"}/>
                <TeamItem picture={new1} name={"M"} role={"Cybersecurity & Smart Contract "} twitter={"https://twitter.com/twiceaime"}/>
                <TeamItem picture={jothomas} name={"RYU"} role={"Smart Contract & Script Developer"}/>                   
            </div>
        </div>
    );
}

export default Team;