import React from "react";
import "./RoadMap.css";
//import { Chrono } from "react-chrono";

import { useEffect,useState,useRef } from "react";
import gsap from "gsap";
import coffre from "../assets/roadmap/coffre.png";
import coffre2 from "../assets/roadmap/coffre2.png";
import echelle from "../assets/roadmap/echelle.png";
import echelle2 from "../assets/roadmap/echelle2.png";
// import gold from "../assets/roadmap/goldpot.png";
// import gold2 from "../assets/roadmap/goldpot2.png";
import digi from "../assets/roadmap/digi.png";
import pile from "../assets/roadmap/pile.png";
import pile2 from "../assets/roadmap/pile2.png";
import portail from "../assets/roadmap/portail.png";
import portail2 from "../assets/roadmap/portaldigi2.png";
import logomanga from "../assets/roadmap/logomanga.png";
import logomanga2 from "../assets/roadmap/logomanga2.png";
import pilemanga from "../assets/roadmap/pile-manga.png"
import pilemanga2 from "../assets/roadmap/pile-manga2.png"


const DemoLargeBoxContent = ({description1,description2,imagedes,topro}) => {
    return (
        <>
            
            <div className="largebox-demo">
                <strong style={{color : 'black'}}>{topro}</strong>
                <br/>
                <br/>
                {description1}
                <br /><br />
                {description2}
            </div>
            <div className="image_div" >
               <img src={imagedes} alt = ''/>
            </div>
        </>
    )
}
const DemoChildren1 = ({titre}) => {
    return (
        <div className="children-titre1" style={{color:'black',fontFamily : 'SpaceGrotesk'}}>
            {titre}
        </div>
    )
}
const DemoChildren2 = ({titre}) => {
    return (
        <div className="children-titre2" style={{color:'black'}}>
            {titre}
        </div>
    )
}
const DemoChildren3 = ({titre}) => {
    return (
        <div className="children-titre3" style={{color:'black'}}>
            {titre}
        </div>
    )
}
const DemoChildren4 = ({titre}) => {
    return (
        <div className="children-titre4" style={{color:'black'}}>
            {titre}
        </div>
    )
}
const DemoChildren5 = ({titre}) => {
    return (
        <div className="children-titre5" style={{color:'black'}}>
            {titre}
        </div>
    )
}
const DemoChildren6 = ({titre}) => {
    return (
        <div className="children-titre6" style={{color:'black'}}>
            {titre}
        </div>
    )
}
const DemoChildren7 = ({titre}) => {
    return (
        <div className="children-titre7" style={{color:'black'}}>
            {titre}
        </div>
    )
}

function LayoutShiftAnimation() {

    

    const description11 = "The portals are open. The Kins of the other worlds are amongst us. Our team has been working tirelessly on developing Shinsekai’s concept, story, and 3D designs.The Shinsekai Team anticipates to move seamlessly from the NFT collection, to the Metaverse, and then to the physical world, by growing the brand to exist in the grander digital and physical worlds, developing into a manga, anime, and even a clothing brand.";
    const description12 = " The great battle is about to begin. Our name will be known, and our existence shall echo through all eternity.";
    const description21 = "Our team is 23 and growing, working both part- and full-time. The Shinsekai Captains, Lieutenants, and Officers are protecting, managing, and working to grow our community - building a strong foundation and authentic bond with those who truly believe in our project.We are also working to invite people from our very own community onto the team while ensuring everyone onboard the Shinsekai Team is helping us build towards our goal and vision.";
    const description22 = "To you, we aim for harmony - to you, we promise our best. We will let you know which ones exactly a bit later. We will always remember everyone who has helped us come thus far, and we will make sure to reward them accordingly.";
    const description31 = "Carefully and thoughtfully curated collection of 10,000 unique Japanese styled 3D NFTs. Every trait has been created with a detailed-eye and a high standard for quality.";
    const description32 = "We are breathing life to the characters from the work-in-progress manga.";
    const description41 = "Every panel in the manga is being hand-drawn, and every character is given a story that exceeds the test of time.All characters developed in the story of Shinsekai will be in the NFT collection and will all play a very specific role.";
    const description42 = "The manga will link the old worlds to the new anime world. Once the first chapter is completed, you will be able to read it on our website.";
    const description51 = "Stake your Shinsekai NFT -> Earn $Shin tokens ->  Purchase Shinsekai merch, top whitelist spots, alpha passes, NFTs, manga, and even web3 jobs like moderation and much more !";
    const description52 = " $Shin will be the dream utility token that the NFT space has been missing. When you join our community, you gain control — a voice in creating the story.Although we will first centralize the management of Shinsekai to develop the project and establish a solid high council, once we establish the DAO, the community will get the opportunity to choose what they want, through a robust voting system, by submitting suggestions and casting ballots on several topics and proposals. A community pool will be available and managed by the DAO.";
    const description61 = "The brand of Shinsekai will fuse the digital world and the physical world. Through different events will be organized to unite the Manga world with the NFT space. Not every Manga fan will know about NFTs, but at least they will have the opportunity to discover this world through our meetups and NFT-nerd members ! "
    const description62 = "We’re excited to meet you at some of the biggest manga conventions in the world: Comiket Japan, Paris Manga, Anime Expo, LA Comic Con!";
    const description71 = "Our community will have a new and unparalleled Manga and Anime experience. Shinsekai is a universal virtual world where everyone will be represented by their 3D NFT avatar. Shinsekai will be your new reality—you will be able to do all the things you do in the real world, such as meeting friends, making new acquaintances, working, gaming and much more but with a lot more fun ! Our goal is to make your dreams come true and allow you to embody characters that push the limits of your imagination in the 'real world'";
    const description72 = "Shinsekai’s ultimate goal is to build it’s own metaverse. We understand that this is not an easy task and that is why in meantime we will be purchasing metaverse lands in other metaverses for you to use your 3D NFT as your avatar. We will send you your NFT in .blend, .obj, .fbx, and .glb formats. You have full rights upon purchase.";

    return (
    <div className="roadmap-container" >
        <div className="header-roadmap">
            <h1>
                ROADMAP
            </h1>
        </div>
        <section className="layout_wrapper" >
            <div className="box_1">
                <AnimatedBox largeBoxContent={<DemoLargeBoxContent description1={description11} description2={description12} imagedes={digi} topro={"Phase 1 | Our Vision"}/>} backgroundSrc={digi}>
                    <DemoChildren1 titre={"Phase 1 | Our Vision"} />
                </AnimatedBox>
            </div>
            <div className="box_2">
                <AnimatedBox largeBoxContent={<DemoLargeBoxContent description1={description21} description2={description22} imagedes={echelle2} topro={"Phase 2 | Community"} />} backgroundSrc={echelle}>
                    <DemoChildren2 titre={"Phase 2 | Community"} />
                </AnimatedBox>
            </div>
            <div className="box_3">
                <AnimatedBox largeBoxContent={<DemoLargeBoxContent description1={description31} description2={description32} imagedes={pile2} topro={"Phase 3 | NFT Collection"} />} backgroundSrc={pile}>
                    <DemoChildren3 titre={"Phase 3 | NFT Collection"} />
                </AnimatedBox>
            </div>
            <div className="box_4">
                <AnimatedBox largeBoxContent={<DemoLargeBoxContent description1={description41} description2={description42} imagedes={pilemanga2} topro={"Phase 4 | Manga"} />} backgroundSrc={pilemanga}>
                    <DemoChildren4 titre={"Phase 4 | Manga"} />
                </AnimatedBox>
            </div>
            <div className="box_5">
                <AnimatedBox largeBoxContent={<DemoLargeBoxContent description1={description51} description2={description52} imagedes={logomanga2} topro={"Phase 5 | Ecosystem"} />} backgroundSrc={logomanga}>
                    <DemoChildren5 titre={"Phase 5 | Ecosystem"} />
                </AnimatedBox>
            </div>
            <div className="box_6">
                <AnimatedBox largeBoxContent={<DemoLargeBoxContent description1={description61} description2={description62} imagedes={coffre2} topro={"Phase 6 | Physical Events"}/>} backgroundSrc={coffre}>
                    <DemoChildren6 titre={"Phase 6 | Physical Events "}/>
                </AnimatedBox>
            </div>
            <div className="box_7">
                <AnimatedBox largeBoxContent={<DemoLargeBoxContent description1={description71} description2={description72} imagedes={portail2} topro={"Phase 7 | Shinsekai Metaverse"} />} backgroundSrc={portail}>
                    <DemoChildren7 titre={"Phase 7 | Shinsekai Metaverse"} />
                </AnimatedBox>
            </div>
        </section>
    </div>
    )
}

export default LayoutShiftAnimation


const AnimatedBox = ({ children, backgroundSrc, largeBoxContent }) => {
    // State
    const [boxCurrentPos, setBoxCurrentPos] = useState({})

    // Ref
    const animated_box_wrapper = useRef(null)
    const content_wrapper_ref = useRef(null)
    const animated_large_box_ref = useRef(null)
    const close_icon_ref = useRef(null)

    // Utils
    const withPx = (val) => {
        return val + 'px'
    }

    // Effects
    useEffect(() => {

        setTimeout(() => {
            let origin = '';
            const layoutRect = document?.querySelector('.layout_wrapper')?.getBoundingClientRect()
            const boxRect = animated_box_wrapper.current?.getBoundingClientRect()

            if (layoutRect.top.toFixed(0) === boxRect.top.toFixed(0) && layoutRect.left.toFixed(0) === boxRect.left.toFixed(0)) {
                origin = 'top left'
            } else if (layoutRect.top.toFixed(0) === boxRect.top.toFixed(0) && layoutRect.right.toFixed(0) === boxRect.right.toFixed(0)) {
                origin = 'top right'
            } else if (layoutRect.top.toFixed(0) === boxRect.top.toFixed(0) && layoutRect.right.toFixed(0) !== boxRect.right.toFixed(0) && layoutRect.left.toFixed(0) !== boxRect.left.toFixed(0)) {
                origin = 'top center'
            } else if (layoutRect.bottom.toFixed(0) === boxRect.bottom.toFixed(0) && layoutRect.left.toFixed(0) === boxRect.left.toFixed(0)) {
                origin = 'bottom left'
            } else if (layoutRect.bottom.toFixed(0) === boxRect.bottom.toFixed(0) && layoutRect.right.toFixed(0) === boxRect.right.toFixed(0)) {
                origin = 'bottom right'
            } else if (layoutRect.bottom.toFixed(0) === boxRect.bottom.toFixed(0) && layoutRect.right.toFixed(0) !== boxRect.right.toFixed(0) && layoutRect.left.toFixed(0) !== boxRect.left.toFixed(0)) {
                origin = 'bottom center'
            } else if (layoutRect.left.toFixed(0) === boxRect.left.toFixed(0) && layoutRect.top.toFixed(0) !== boxRect.top.toFixed(0) && layoutRect.bottom.toFixed(0) !== boxRect.bottom.toFixed(0)) {
                origin = 'left center'
            } else if (layoutRect.right.toFixed(0) === boxRect.right.toFixed(0) && layoutRect.top.toFixed(0) !== boxRect.top.toFixed(0) && layoutRect.bottom.toFixed(0) !== boxRect.bottom.toFixed(0)) {
                origin = 'right center'
            } else {
                origin = 'center center'
            }

            setBoxCurrentPos({
                top: withPx(boxRect.top - layoutRect.top),
                left: withPx(boxRect.left - layoutRect.left),
                width: withPx(boxRect.width),
                height: withPx(boxRect.height),
                transformOrigin: origin
            })
        }, 200);

    }, [])


    // Gsap Timeline
    let timeline;

    useEffect(() => {
        setTimeout(() => {
            const layoutRect = document?.querySelector('.layout_wrapper')?.getBoundingClientRect()
            timeline = gsap.timeline()
            timeline.pause()

            timeline.to(animated_large_box_ref.current, {
                top: 0,
                left: 0,
                width: layoutRect.width,
                height: layoutRect.height,
                zIndex: '50',
                duration: 0.35,
                pointerEvents: 'all',
                opacity: 1
            }).to(content_wrapper_ref.current, {
                opacity: 1,
                duration: 0.2
            }).to(close_icon_ref.current, {
                opacity: 1,
                duration: 0.1
            })
        }, 200);
    })

    const playAnimation = () => timeline.play()
    const closeAnimation = () => timeline.reverse(0.5)

    return (
        <>
            <div className="animated_box_wrapper" style={{ backgroundImage: `url(${backgroundSrc})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} ref={animated_box_wrapper} onClick={playAnimation}>
                {children}
            </div>

            {/* Animated Large Box --Start-- */}
            <div className="animated_large_box_wrapper" ref={animated_large_box_ref} style={boxCurrentPos}>
                <div className="content_wrapper" ref={content_wrapper_ref}>
                    {largeBoxContent}
                </div>
                {/* Close large Box --Start-- */}
                <div onClick={closeAnimation} className="close_icon" ref={close_icon_ref} >
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1.8rem" width="1.8rem" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path></svg>
                </div>
                {/* Close large Box --End-- */}
            </div>
            {/* Animated Large Box --End-- */}
        </>
    )
}

