import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import logo2 from "../assets/logo-text.png";
import force from "../assets/1.png";
import eternite from "../assets/3.png";
import loyaute from "../assets/2.png";
import gsap from "gsap";
import { FaTwitter } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import ScrollTrigger from "gsap/ScrollTrigger";
import preloadImage from "./preloadImage";
// import preloadImageChain from "./preloadImage";




function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../assets/frames', false, /\.(png|jpe?g|svg)$/));

let imagez = {};

function Header() {
    function toggleBurgerMenu() {
        document.querySelector('.navbar-menu').classList.toggle('is-active');
      }
   
    gsap.registerPlugin(ScrollTrigger)

    const videoContainerRef = useRef(null)

    const [width, setWidth] = useState(window.innerWidth);

    const isMobile = width <= 768;

    let currentStep = isMobile ? 4 : 4;

    function loadImage(step, callback) {
        for (var i = 0; i <= 227; i+=step){
            callback(i);
        }
    }

    function loadImages(i) {
        let finalFinalImage = ''
        if (i <= 9) {
            finalFinalImage = '000' + i
        } else if (i <= 99) {
            finalFinalImage = '00' + i
        } else if (i >= 100) {
            finalFinalImage = '0' + i
        }

        // Make sure arr is actually an array and any other error checking
        imagez[i] = preloadImage(images[`${finalFinalImage}.jpg`]);
    }

    function drawCanvasImg(e) {
        const image = new Image();
        let currentFrame = Math.round(e.frame * e.endFrame / currentStep) * currentStep;
        let finalFrame = currentFrame.toFixed(0);
        //console.log(finalFrame);

        let finalFinalImage = ''
        if (finalFrame <= 9) {
            finalFinalImage = '000' + finalFrame
        } else if (finalFrame <= 99) {
            finalFinalImage = '00' + finalFrame
        } else if (finalFrame >= 100) {
            finalFinalImage = '0' + finalFrame
        }

        image.src = e.images[`${finalFinalImage}.jpg`];

        image.onload = function () {
            var canvasContext = e.canvas.getContext('2d');
            var wrh = image.width / image.height;
            var newWidth = e.canvas.width;
            var newHeight = newWidth / wrh;
            if (newHeight > e.canvas.height) {
                newHeight = e.canvas.height;
                newWidth = newHeight * wrh;
            }
            var xOffset = newWidth < e.canvas.width ? ((e.canvas.width - newWidth) / 2) : 0;
            var yOffset = newHeight < e.canvas.height ? ((e.canvas.height - newHeight) / 2) : 0;

            canvasContext.drawImage(image, xOffset, yOffset, newWidth, newHeight);
        }

        return {
            currentFrame: currentFrame,
        };
    }

    useEffect(() => {
        const canvas = document.getElementById("global_canvas");

        canvas.width = 1520;
        canvas.height = 1030;

        gsap.to(".homepage-container", {
            scrollTrigger: {
                trigger: ".homepage-container",
                pin: true,
                anticipatePin: 1,
                fastScrollEnd: true,
                refreshPriority: 1,
                start: "top top",
                end: "bottom top",
                markers: false,
                scrub: true,
                onUpdate: function (tl) {
                    let progress = tl.progress;
                    drawCanvasImg({
                        frame: progress,
                        startFrame: 0,
                        endFrame: 200,
                        canvas: canvas,
                        images: images
                    })
                },
            },
        });

        loadImage(32, loadImages);
        loadImage(16, loadImages);
        loadImage(8, loadImages);
        loadImage(4, loadImages);
        //loadImage(2, loadImages);
        //loadImage(1, loadImages);

        // const controller = new ScrollMagic.Controller()
        // const scene = new ScrollMagic.Scene({
        //     triggerElement: videoContainerRef.current,
        //     duration: 500,
        //     triggerHook: 0
        // }).addTo(controller).setPin(videoContainerRef.current)

        // scene.on('update', (e) => {
        //     let frame = e.scrollPos / 500
        //      console.log(frame);
        //     drawCanvasImg({
        //         frame: frame,
        //         startFrame: 1,
        //         endFrame: 227,
        //   })
        // })

        

    }, [])

    // useEffect(()=>{

    //         burgerIcon.addEventListener('click',() =>{
    //         navbarMenu.classList.toggle('is-active')
    //      })
    // },[])

    

    

    return (
        <div className='homepage-container' >
            <nav className="navbar is-transparent ">
  <div className="navbar-brand">
    <a rel="noreferrer" className="navbar-item" href="/">
        <img id='l-img' src={logo2} alt='logo'/>
            <p className="text-logo-new">
                SHINSEKAI
            </p>
    </a>
    {/* <div  className="navbar-burger  is-grouped mt-5" > */}
                        {/* <a id="twitter-mobile" rel="noreferrer" target="_blank" href="https://twitter.com/shinsekaicorp">
                            <FaTwitter  style={{fill : 'white',width: '100%',fontSize:'25px',marginBottom:'5%'}}/>
                        </a>
                      
                        <a rel="noreferrer" target="_blank" href='https://t.co/bUper2SbeI' >
                            <FaDiscord style={{fill : 'white',width: '100%',fontSize:'25px'}}/>
                        </a> */}

                        
    {/* </div> */}
    <a  className="navbar-burger" id="burger" onClick={toggleBurgerMenu}>
        <span></span>
        <span></span>
        <span></span>
    
    </a>
  </div>

  <div className="navbar-menu"  id="nav-links">
      <div className="navbar-end">
      <a className="navbar-item" id="twitter-mobile" rel="noreferrer" target="_blank" href="https://twitter.com/shinsekaicorp">
                <FaTwitter  style={{fill : 'white',width: '100%',fontSize:'25px',marginBottom:'5%'}}/>
      </a>
      <a id="discord-mobile" rel="noreferrer" target="_blank" href='https://t.co/bUper2SbeI' >
                <FaDiscord style={{fill : 'white',width: '100%',fontSize:'25px'}}/>
      </a>

      </div>
    </div>

  <div id="navMenu" className="navbar-menu ">

    <div className="navbar-end">
      <div className="navbar-item">
        <div className="field is-grouped">
                    <div className='link-twitter-container'>
                        <img alt="force" src={force} id='force-image'/>
                        <a rel="noreferrer" target="_blank" href='https://twitter.com/shinsekaicorp' id='twitter' >
                            Twitter
                        </a>
                    </div>
                    <div className='link-twitter-container'>
                        <img alt="force" src={loyaute} id='force-image'/>
                        <a rel="noreferrer" target="_blank" href='https://t.co/bUper2SbeI' id='discord' >
                            Discord
                        </a>
                    </div>

                    <div className='link-opensea-container'>
                        <img alt="eternite" src={eternite} id='eternite-image'/>
                        <a rel="noreferrer"   href='https://opensea.io/OfficialShinsekaiCorp' target="_blank" id='opensea'>
                            View On Opensea
                        </a>
                    </div> 
                </div>
      </div>
    </div>
  </div>
</nav>
            <div className='header-container'>
                <div className="but-vid">
                    <div className="video-container" ref={videoContainerRef}>
                        <canvas id="global_canvas" fetchpriority="high"></canvas>
                    </div>
                </div>
                {/* <div className='logo-container'>
                    <img id='l-img' src={logo2} alt='logo'/>
                </div> */}
                <div className="title-mobile-container">
                    <p className="text-logo-new-mobile">
                        SHINSEKAI
                    </p>
                </div>
                <div className='buttons-mangaverse'>
                            <a rel="noreferrer" id='but-mangaverse' target="_blank" href='https://t.co/bUper2SbeI' >
                                JOIN THE MANGAVERSE
                            </a>
                </div>
            
            </div>
        </div>

    );
}


export default Header;