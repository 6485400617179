import React from "react";
import "./TeamItem.css";
import { useEffect } from "react";
import gsap from "gsap";
import { FaLinkedin, FaInstagram, FaTwitter, FaTiktok } from "react-icons/fa";

function TeamItem({picture, name, role, linkedin, instagram, tiktok, twitter }){

    useEffect(()=>{
        const target2 = '.item-container div'

        // You can set your initial animation.
        // Like i set opacity 0 for this.
        gsap.set(target2, {
            opacity: 0
        })

        // And here all the div is looping through
        document.querySelectorAll(target2).forEach((eachDiv, index) => {
            gsap.to(eachDiv, {
                scrollTrigger: {
                    trigger: eachDiv,
                    start: "top 75%",
                    end: "top center",
                    marker: true,
                    scrub: true,
                },
                duration: 100,
                // Here you will set your animation, That need to play when they reached the viewport.
                opacity: 1
            })
        })
    },[])


    return(
        <div className="item-container column is-3 is-12-mobile">
            <div>
                <img id="team-image" alt="team" src= {picture}/>
            </div>
            <div className="team-role">
                <p className="team-name-text">
                    {name}
                </p>
                <p className="team-role-text">
                    {role}
                </p>
                <p className="team-link">
                    {instagram && (
                    <a rel="noreferrer" target="_blank" href={instagram}>
                        <FaInstagram  style={{fill : 'white'}}/>
                    </a>)}
                    {tiktok && (
                    <a rel="noreferrer" target="_blank" href={tiktok}>
                        <FaTiktok  style={{fill : 'white', marginLeft:'0.6vw'}}/>
                    </a>)}
                    {twitter && (
                    <a rel="noreferrer" target="_blank" href={twitter}>
                        <FaTwitter style={{fill : 'white', marginLeft:'0.6vw'}}/>
                    </a>)}
                    {linkedin && (
                    <a rel="noreferrer" target="_blank" href={linkedin}>
                        <FaLinkedin  style={{fill : 'white', marginLeft:'0.6vw'}}/>
                    </a>)}
                </p>
            </div>

        </div>
    );
}


export default TeamItem;