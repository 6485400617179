import React, {useState, useContext} from "react";
import "./Footer.css";
import Modal from 'react-modal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { FaDiscord,FaInstagram,FaTwitter} from "react-icons/fa";
import term from "../assets/termsandconditionsshinsekai.pdf"
import { Link } from "react-router-dom";

function Footer(){

    const [modalIsOpen, setIsOpen] = useState(false);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
        overlay: {zIndex: 1000}
      };

    return(
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Shinsekai Term & Conditions"
            >
                <Document file={term}>
                    <Page size="A4" pageNumber={pageNumber} />
                </Document>
            </Modal>
        <div className="footer-container">
                <div className="social-footer">
                    <a rel="noreferrer" target="_blank" href="https://www.instagram.com/shinsekaicorp/">
                        <FaInstagram  style={{fill : 'white'}}/>
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://t.co/bUper2SbeI">
                        <FaDiscord  style={{fill : 'white'}}/>
                    </a>
                    <a rel="noreferrer" target="_blank" href="https://twitter.com/shinsekaicorp">
                        <FaTwitter  style={{fill : 'white'}}/>
                    </a>

                </div>
                <div className="footer-text">
                    2022 ShinSekaiCorp | All rights reserved 
                </div>
                <div className="termConditions-text">
                    <a rel="noreferrer" target="_blank" onClick={openModal}>
                        Shinsekai Term & Conditions
                    </a> 
                </div>
            </div>
        </div>
    );
}

export default Footer;