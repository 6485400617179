export function preloadImage(path){
    var img = new Image();
    img.onload = function () {}
    img.src = path;
    return img;
}

export function preloadImageChainNoWait(paths){
    for(let i = 0; i <= paths.length; i++){
        let img = new Image()
        img.src = paths[i];
    }
}

export function preloadImageChain(paths, i=0){
    let img = new Image()
    img.onload = function (){
        if (i+1 < paths.length){
            preloadImageChain(paths, i+1);
        }
    }
    img.src = paths[i];
}


export default preloadImage;